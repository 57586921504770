import React from 'react'

const TopBar = () => {
  return (
    <div className='topbar'>
      <a href="/" className='topbar_item' >OPPLOANS.COM</a>
    </div>
  )
}

export default TopBar